// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-doradztwo-js": () => import("./../../../src/pages/doradztwo.js" /* webpackChunkName: "component---src-pages-doradztwo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lakiernictwo-js": () => import("./../../../src/pages/lakiernictwo.js" /* webpackChunkName: "component---src-pages-lakiernictwo-js" */),
  "component---src-pages-magazynowanie-js": () => import("./../../../src/pages/magazynowanie.js" /* webpackChunkName: "component---src-pages-magazynowanie-js" */),
  "component---src-pages-produkty-js": () => import("./../../../src/pages/produkty.js" /* webpackChunkName: "component---src-pages-produkty-js" */),
  "component---src-pages-referencje-js": () => import("./../../../src/pages/referencje.js" /* webpackChunkName: "component---src-pages-referencje-js" */),
  "component---src-pages-rekrutacja-js": () => import("./../../../src/pages/rekrutacja.js" /* webpackChunkName: "component---src-pages-rekrutacja-js" */),
  "component---src-pages-spawalnictwo-js": () => import("./../../../src/pages/spawalnictwo.js" /* webpackChunkName: "component---src-pages-spawalnictwo-js" */)
}

